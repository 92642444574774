:root {
  --error-text: #fb5959;
  --error-bg: #ffefef;
  --brand-color: #2467db;
  --brand-comp-color: #db9824;
  --focus-border-color: #438fee;
  --btn-color: #4883e9;
  --btn-comp-color: white;
  --btn-border-color: #1a5aca;
  --btn-shadow-color: #0c4cba;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

a,
button {
  cursor: pointer;
}

.app {
  max-width: 480px;
  margin: 0 auto;
}

.link {
  color: var(--brand-color);
}

.hidden {
  display: none !important;
}

.header {
  display: flex;
  margin: 1.5rem 1rem;
  font-size: 1.25rem;
  gap: 0.375rem;
  color: var(--brand-color);
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #777777;
  font-size: 0.75rem;
  margin: 1.5rem 0 1.25rem;
  flex-direction: column;
}

.footer .logout {
  font-size: 1rem;
  color: var(--brand-color);
  margin-bottom: 0.75rem;
}

.footer .language-switcher {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.footer .language-switcher .link {
  padding: 0.25rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.entries {
  margin: 1rem;
  border: 1px solid #d5d5d5;
  border-radius: 0.25rem;
}

.entries[data-count='0'] {
  display: none;
}

.entries.error {
  color: var(--error-text);
  display: flex;
  min-height: 3rem;
  align-items: center;
  justify-content: center;
  background-color: var(--error-bg);
  border-color: var(--error-text);
}

.entry {
  padding: 0 0.5rem;
  min-height: 2.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.entry:nth-child(odd) {
  background-color: #f9f9f9;
}

.entry {
  min-height: 4rem;
  padding: 0.5rem 1rem;
}

.entry .details {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
}

.entry .name {
  color: var(--brand-color);
  font-size: 1rem;
  line-height: 1.5rem;
}

.entry .place {
  color: #616161;
  font-size: 0.75rem;
  line-height: 1.25rem;
}

.entry .event {
  color: #7a7a7a;
  font-size: 0.75rem;
  line-height: 1.25rem;
}

.entry .value {
  color: #666666;
}

.upload-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
}

.upload-button {
  color: var(--btn-comp-color);
  background-color: var(--btn-color);
  font-weight: bold;
  padding: 0.5rem 1rem;
  border: 1px solid var(--btn-border-color);
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px var(--btn-shadow-color);
}

.search-container {
  margin: 1rem 1rem 0.5rem 1rem;
  position: relative;
}

.count {
  display: flex;
  justify-content: center;
  margin: 0 1rem;
  font-size: 0.875rem;
  color: #888888;
}

.search-container input {
  width: 100%;
  height: 2.5rem;
  border: 1px solid #d5d5d5;
  border-radius: 0.25rem;
  padding: 0 2.75rem 0 1rem;
}

.search-container input::placeholder {
  color: #aaaaaa;
}

.search-container input:focus {
  border-color: var(--focus-border-color);
}

.search-icon {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #777777;
  font-size: 1.25rem;
}

.login-container {
  display: flex;
  justify-content: center;
}

.login-form {
  width: 100%;
  margin: 0 1.5rem;
  border: 1px solid #d5d5d5;
  padding: 1.5rem;
  border-radius: 0.375rem;
}

.login-form .input-group {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

.login-form .input-group:last-child {
  margin-bottom: 0.25rem;
}

.login-form .input-group label {
  color: #333333;
}

.login-form .input-group input {
  width: 100%;
  height: 2.5rem;
  border: 1px solid #d5d5d5;
  border-radius: 0.25rem;
  padding: 0 2.75rem 0 1rem;
  margin-top: 0.25rem;
  font-size: 1rem;
  color: #333333;
}

.login-form .input-group input:focus {
  border-color: var(--focus-border-color);
}

.login-form .input-group button {
  width: 100%;
  min-height: 2.5rem;
  color: var(--btn-comp-color);
  background-color: var(--btn-color);
  font-weight: bold;
  font-size: 1rem;
  border: 1px solid var(--btn-border-color);
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  box-shadow: 0 1px 2px var(--btn-shadow-color);
}

.login-form .error {
  color: var(--error-text);
  text-align: center;
  margin-bottom: 0.5rem;
  background-color: var(--error-bg);
  border: 1px solid var(--error-text);
  border-radius: 0.25rem;
  min-height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Spinner - Start */
.spinner-container {
  display: flex;
  margin-top: 2rem;
  min-height: 4rem;
  align-items: center;
  justify-content: center;
}

.loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  color: var(--brand-color);
}
.loader:before,
.loader:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}
.loader:after {
  color: var(--brand-comp-color);
  transform: rotateY(70deg);
  animation-delay: 0.4s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: 0.2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: 0.2em 0.2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 0.2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -0.2em 0.2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -0.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -0.2em -0.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -0.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: 0.2em -0.2em 0 0 currentcolor;
  }
}
/* Spinner - End */

.page-not-found {
  color: var(--error-text);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  min-height: 6rem;
  font-size: 1.25rem;
  flex-direction: column;
}

.pfwiki {
  text-decoration: none;
  color: #333333;
}

.pfwiki:hover {
  color: var(--brand-color);
}
